import React, { FC } from "react";
import SEO from "../components/seo";
import { Layout } from "../components/layout";
import { graphql, Link } from "gatsby";
import { WideContainer } from "../components/container";
import Img from "gatsby-image";
import { Header } from "../components/header";
import { intersection, chunk } from "lodash";
import styled from "styled-components";
import { HtmlContent } from "../components/html-content";
import { ProjectsTemplateQuery } from "../../graphql-types";

type Props = {
  data: ProjectsTemplateQuery;
};

const ProjectsPage: FC<Props> = (props) => {
  const [selectedServices, updateSelectedServices] = React.useState(
    [] as string[]
  );

  const emptyFilterLabel = "Toon alles";

  const filteredProjects = props.data.allWpProject.edges.filter((edge) =>
    selectedServices.length > 0
      ? intersection(
          edge.node.projectCustomFields?.services?.map(
            (service) => service?.title
          ) || [],
          selectedServices
        ).length !== 0
      : true
  );

  const numberOfProjectsForService = (service: string) =>
    props.data.allWpProject.edges.filter((edge) =>
      (
        edge.node.projectCustomFields?.services?.map(
          (service) => service?.title
        ) || []
      ).includes(service)
    ).length;

  return (
    <Layout>
      <SEO title="Projecten" />

      {props.data.wpPage?.featuredImage?.node?.localFile?.childImageSharp
        ?.fluid && (
        <Header
          backgroundImage={
            props.data.wpPage?.featuredImage?.node?.localFile?.childImageSharp
              ?.fluid
          }
        >
          {props.data.wpPage?.title}
        </Header>
      )}

      <WideContainer style={{ paddingTop: "3em", paddingBottom: 50 }}>
        <HtmlContent
          dangerouslySetInnerHTML={{ __html: props.data.wpPage?.content || "" }}
        />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <ServicesWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ServiceTitle style={{ padding: 10 }}>
                {selectedServices.length > 0
                  ? selectedServices.join(", ")
                  : emptyFilterLabel}
              </ServiceTitle>

              <span
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: "6px solid transparent",
                  borderRight: "6px solid transparent",
                  borderTop: "8px solid #00a88e",
                  marginLeft: 8,
                }}
              />
            </div>

            <ServicesList>
              <ServicesListItem>
                <ServiceLabel>
                  <input
                    type="checkbox"
                    checked={selectedServices.length === 0}
                    onChange={() => updateSelectedServices([])}
                  />
                  <ServiceTitle isHighlighted={selectedServices.length === 0}>
                    {emptyFilterLabel}
                  </ServiceTitle>
                  <ItemCount>
                    ({props.data.allWpProject.edges.length})
                  </ItemCount>
                </ServiceLabel>
              </ServicesListItem>

              {props.data.allWpService.edges
                .map((edge) => edge.node.title)
                .filter(numberOfProjectsForService)
                .map((service, i) => (
                  <ServicesListItem key={i}>
                    <ServiceLabel>
                      <input
                        type="checkbox"
                        checked={selectedServices.includes(service)}
                        onChange={() =>
                          updateSelectedServices(
                            selectedServices.includes(service)
                              ? selectedServices.filter(
                                  (selectedService) =>
                                    selectedService !== service
                                )
                              : [...selectedServices, service]
                          )
                        }
                      />
                      <ServiceTitle
                        isHighlighted={selectedServices.includes(service)}
                      >
                        {service}
                      </ServiceTitle>
                      <ItemCount>
                        ({numberOfProjectsForService(service)})
                      </ItemCount>
                    </ServiceLabel>
                  </ServicesListItem>
                ))}
            </ServicesList>
          </ServicesWrapper>

          <ProjectSequences>
            {chunk(filteredProjects, 4).map((projectSequence, i) => {
              const SequenceComponent = !(i % 2)
                ? ProjectSequence
                : ProjectSequenceReversed;

              console.log(projectSequence);

              return (
                <SequenceComponent key={i}>
                  {projectSequence.map((project, i) => (
                    <Project key={i}>
                      <Link to={project.node.uri}>
                        {project.node?.projectCustomFields?.images?.[0]
                          ?.localFile.childImageSharp.fluid && (
                          <Img
                            fluid={
                              project.node?.projectCustomFields?.images?.[0]
                                ?.localFile.childImageSharp.fluid
                            }
                            style={{ height: "100%", width: "100%" }}
                          />
                        )}
                        <ProjectTitle>{project.node.title}</ProjectTitle>
                      </Link>
                    </Project>
                  ))}
                </SequenceComponent>
              );
            })}
          </ProjectSequences>
        </div>
      </WideContainer>

      {/* <pre>{JSON.stringify(services, null, 2)}</pre> */}

      {/* <pre>{JSON.stringify(projects, null, 2)}</pre> */}

      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </Layout>
  );
};

export default ProjectsPage;

const ServicesList = styled.ul`
  position: absolute;
  right: -10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

const ServicesWrapper = styled.div`
  position: relative;
  z-index: 1;
  align-self: flex-end;
  left: -10px;

  ${ServicesList} {
    visibility: hidden;
    opacity: 0;
  }

  &:hover ${ServicesList} {
    visibility: visible;
    opacity: 1 !important;
  }
`;

const ServicesListItem = styled.li`
  display: flex;
  align-items: center;
  transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);

  :hover {
    transform: translateX(5px);
  }

  & * {
    margin-right: 8px;
  }
`;

const ServiceLabel = styled.label`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
`;

type ServiceTitleProps = {
  isHighlighted?: boolean;
};

const ServiceTitle = styled.span<ServiceTitleProps>`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ isHighlighted: isSelected }) =>
    isSelected ? "#00A88E" : "#0b0b0b"};
  transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
`;

const ItemCount = styled.span`
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #919191;
`;

const ProjectTitle = styled.div`
  position: absolute;
  width: calc(100% - 20px);
  bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px;
  text-align: center;
  background-color: #00a88e;
  color: #fff;
`;

export const Project = styled.div`
  position: relative;
  overflow: hidden;
  float: left;
  padding: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    float: none;
    width: 100% !important;
    height: 250px !important;
  }

  & ${ProjectTitle} {
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  & :hover ${ProjectTitle} {
    opacity: 1;
  }
`;

export const ProjectSequence = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 60vw;
    max-height: 725px;
    width: 100%;
  }

  & ${Project}:nth-child(1) {
    height: 100%;
    width: 33.333%;
  }

  & ${Project}:nth-child(2) {
    width: 66.666%;
    height: 50%;
  }

  & ${Project}:nth-child(3) {
    width: 33.333%;
    height: 50%;
  }

  & ${Project}:nth-child(4) {
    width: 33.333%;
    height: 50%;
  }

  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const ProjectSequenceReversed = styled(ProjectSequence)`
  ${Project}:nth-child(1) {
    height: 50%;
    width: 66.666%;
  }

  ${Project}:nth-child(2) {
    float: right;
    width: 33.333%;
    height: 100%;
  }

  & ${Project}:nth-child(3) {
    width: 33.333%;
    height: 50%;
  }

  & ${Project}:nth-child(4) {
    width: 33.333%;
    height: 50%;
  }
`;

const ProjectSequences = styled.div`
  margin-left: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: -10px;
  }

  & ${ProjectSequence} {
    flex-shrink: 0;
  }
`;

export const query = graphql`
  query ProjectsTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    allWpProject {
      edges {
        node {
          uri
          title
          projectCustomFields {
            services {
              ... on WpService {
                title
              }
            }
            images {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpService {
      edges {
        node {
          title
        }
      }
    }
  }
`;
